import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GrCopy, GrDocumentPdf, GrDocumentVerified } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../constants/utils';
import { IconButton } from '../../../styles';

const OrdersTable = ({
  shownOrders,
  approvePendingOrder,
  duplicateOrderFromHistory,
  isAdmin,
  isLoadingAction,
  isLimitedCustomer
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TableContainer component={Paper} sx={{ marginY: 2 }}>
        <Table size='small' id='orders-table'>
          <TableHead>
            <TableRow>
              <TableCell>{t('tables.number')}</TableCell>
              <TableCell>{t('tables.date')}</TableCell>
              <TableCell>{t('tables.status')}</TableCell>
              <TableCell>{t('tables.quantity')}</TableCell>
              {!isLimitedCustomer && <TableCell>{t('tables.total')}</TableCell>}
              <TableCell>{t('tables.actionsTitle')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shownOrders.map((order) => {
              const {
                _id,
                reference,
                date,
                status,
                forApproval,
                total,
                items,
                filePath
              } = order;
              const docDate = new Date(date).toLocaleDateString('pt-PT');
              const quantity = items.reduce(
                (previousValue, { quantity: { unit, box } }) =>
                  previousValue + (unit + box),
                0
              );
              return (
                <TableRow key={`ordersTable-${reference}`}>
                  <TableCell>{reference}</TableCell>
                  <TableCell>{docDate}</TableCell>
                  <TableCell>{status}</TableCell>
                  <TableCell>{quantity}</TableCell>
                  {!isLimitedCustomer && (
                    <TableCell>{formatCurrency(total)}</TableCell>
                  )}
                  <TableCell>
                    {isAdmin && forApproval && (
                      <IconButton
                        size='small'
                        title={t('tables.actions.approveOrder')}
                        onClick={() => approvePendingOrder(_id)}
                        disabled={isLoadingAction}
                      >
                        <GrDocumentVerified size={16} />
                      </IconButton>
                    )}
                    <IconButton
                      size='small'
                      title={t('tables.actions.buyAgain')}
                      onClick={() => duplicateOrderFromHistory(_id)}
                      disabled={isLoadingAction}
                    >
                      <GrCopy size={16} />
                    </IconButton>
                    {filePath && (
                    <IconButton
                      size='small'
                      title={t('tables.actions.exportPdf')}
                      href={filePath}
                    >
                      <GrDocumentPdf size={16} />
                    </IconButton>
                  )
                    }
                    {!filePath && (
                      <IconButton
                        size='small'
                        title={t('tables.actions.exportPdf')}
                        disabled={true}
                      >
                        <GrDocumentPdf size={16} />
                      </IconButton>
                    )}

                    <Button
                      component={Link}
                      to={reference}
                      state={{
                        order: order
                      }}
                      title='Ver detalhe'
                      variant='link'
                      sx={{
                        padding: 1
                      }}
                    >
                      Ver detalhe
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrdersTable;
